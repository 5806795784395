// // 引入createStore对象
// import { createStore } from 'redux'

// // 引入reducer
// import reducer from './reducer'

// const store = createStore(
//     reducer,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// export default store;


import { createStore } from 'redux'
import reducer from './reducer'

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
const persistConfig = {
    key: 'root',
    storage: storage,

}
const myPersistReducer = persistReducer(persistConfig, reducer)
const store = createStore(myPersistReducer)
export const persistor = persistStore(store)
export default store