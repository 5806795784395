import React, { useState, useEffect, useRef } from 'react'
import "./css/footer.less"
import "./css/modal.less"

import store from '../store'
import { Carousel, Button, Modal, Form, Input, message, Progress, Row, Col } from "antd";
import { LockOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';
import { touristopenaccount, fetchname, checkplayername } from "../request/api"
import zxcvbn from 'zxcvbn';
export default function Model(props) {
  const basic2 = useRef(null)
  const storestate = store.getState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState({ msg: "", value: "" });
  const [nameshow, setNameshow] = useState(false);
  const [officialname, setOfficialname] = useState("");
  let [tourist, setTourist] = useState("")
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const firstLetterToLowercase=(str)=> {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  const onFinish = (values) => {
    console.log("登录", basic2.current.validateFields);


    basic2.current.validateFields().then(() => {
      console.info("-=-=-=-=通过", officialname, state.msg);
      if(nameshow==true){
        return
      }
      // 通过验证


      let name=firstLetterToLowercase(JSON.parse(localStorage.getItem("gamer_reg")).player_name)
      touristopenaccount({
        official_name: officialname,
        password: state.msg,
        name: name
      }).then((res) => {
        console.log(res);
        let gamerreg = JSON.parse(localStorage.getItem("gamer_reg"))
        gamerreg.init_state = 0
        localStorage.setItem("gamer_reg", JSON.stringify(gamerreg));
        // setIsModalOpen(false);
        let UT = JSON.parse(localStorage.getItem("utoken"))
        fetchname({
          utoken: UT,

        }).then(
          (res) => {
            props.setPare();
            console.log(res);
            localStorage.setItem("gamer_reg", JSON.stringify(res.data));
            const action = {
              type: "changeInputValue",       // type属性是必须要写的，用于校验
              value: officialname,          // value代表要修改为什么值
          }
          store.dispatch(action);

            window.location.reload();


          })

      })


    })






  };
  // 游客登录
  const touristonFinish = () => {


let name=firstLetterToLowercase(JSON.parse(localStorage.getItem("gamer_reg")).player_name)


    touristopenaccount({
      name: name,
    }).then((res) => {
      console.log(res);
      let gamerreg = JSON.parse(localStorage.getItem("gamer_reg"))
      gamerreg.init_state = 0
      localStorage.setItem("gamer_reg", JSON.stringify(gamerreg));
      // setIsModalOpen(false);
      let UT = JSON.parse(localStorage.getItem("utoken"))
      fetchname({
        utoken: UT,

      }).then(
        (res) => {
          props.setPare();
          console.log(res);
          localStorage.setItem("gamer_reg", JSON.stringify(res.data));
          window.location.reload();


        })

    })




  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo, props);
  };
  useEffect(() => {
    if(localStorage.hasOwnProperty("gamer_reg")==true){
      setIsModalOpen(props.xdata);
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
      setTourist(gamer_reg.player_name)
    }


    // setTourist(props.tourist)
  })

  // 获取名称是否重复
  const checkname = () => {
    console.log("state.official_name", officialname);

    checkplayername({
      official_name: officialname
    }).then((res) => {
      console.log(res);
      if (res.code == 1) {
        setNameshow(true)
      } else {
        setNameshow(false)
      }
    })
  };

  return (
    <div className='model-yk'>
      <Modal maskClosable={false} closable={false} okText="Login" keyboard="false"  width="900px" open={isModalOpen} onOk={handleOk} footer={[

        <Button className='form-button form-button-color' size='large' type="primary" htmlType="submit" onClick={touristonFinish} block>
          Access as Guest
        </Button>,
        <Button className='form-button' size='large' type="primary" htmlType="submit" onClick={onFinish} block>
          Access as Registered User
        </Button>,
      ]}
      >


        <Form
          name="basic1"

          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <h3>Directly login as guest member</h3> */}
          <div className='tourist'>

            Your Username <br />
            <p>{tourist}</p>

            {/* <p className='form-left-p'>Instant Cheerleader Access</p> */}
            *lf you need to keep your data when clearing browser caches, please access as registered user! <br />
          </div>


          {/* <Form.Item>
        <Button size='large' type="primary" htmlType="submit" block>
          Enter
        </Button>
      </Form.Item> */}

        </Form>
        <div className='space'>
          <div></div>
          OR
          <div></div>
        </div>
        <Form
          name="basic2"
          ref={basic2}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <h3>Please create a password at the first time</h3> */}
          <Form.Item
            name="username"
         
          >
               {/* rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]} */}
            <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />}
              onChange={(e) => setOfficialname(e.target.value)}
              onBlur={checkname} value={officialname} placeholder="Enter a custom username" />
                 <p className='basic2-item-p' style={{display: nameshow == true ? 'block' : 'none' }}>This name has been taken. Please change</p>
          </Form.Item>
       
          {/* <Form.Item

      >

      </Form.Item> */}
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Password can not be empty!',
              },
            ]}
          >
            <Input.Password size="large"
              value={state.msg}
              onChange={e => {
                setState({
                  msg: e.target.value,
                  value: zxcvbn(e.target.value).guesses_log10,
                });
              }}

              prefix={<KeyOutlined />} placeholder="Enter a password" />
          </Form.Item>
          {/* <Form.Item> <meter min="0" max="12" low="4" high="8" optimum="10" value={state.value}></meter></Form.Item> */}



          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Repeat the password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please repeat the password!'));
                },
              }),
            ]}
          >
            <Input.Password size="large" prefix={<KeyOutlined />} placeholder="Repeat the password" />
          </Form.Item>
          <span><Progress percent={state.value * 10} showInfo={false} strokeColor={['#008000', '#ffa500', '#e74242']} steps={3} />Password strength</span>
          {/* <Form.Item></Form.Item> */}
          {/* <Form.Item>
   <Link to="/register">还没账号？立即注册</Link>
      </Form.Item> */}

          {/* <Form.Item>
        <Button size='large' type="primary" htmlType="submit" block>
          Enter
        </Button>
      </Form.Item> */}

        </Form>




      </Modal>
    </div>
  )
}
