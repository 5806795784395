import React from 'react';
import { Button, Form, Input,message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {Link,useNavigate} from 'react-router-dom'
import './css/Login.less'
import logoImg from '../assets/img/logo.png'
import {LoginApi} from '../request/api'
const Login = () => {
  const navigate = useNavigate()

    const onFinish = (values) => {
      LoginApi({
        username: values.username,
        password: values.password
      }).then((res)=>{
        if(res.errCode===0){
          message.success(res.message);
    //  登录成功储存数据
// localStorage.setItem('avatar',res.data.avatar)
// localStorage.setItem('token',res.data['cms-token'])
// localStorage.setItem('username', res.data.username)
// 测试注释需要的时候加上
    // 跳转到首页
    setTimeout(()=>navigate('/'), 1500)
        }else{
          message.error(res.message);
  
        }
      })
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <div className='login'>

<div className='login_box'>
    <img src={logoImg} alt="" />
              <Form
      name="basic"
   
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
   
        name="username"
      
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input  size='large'  prefix={<UserOutlined className="site-form-item-icon" />}   placeholder="请输入用户名" />
      </Form.Item>

      <Form.Item
 
        name="password"
  
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password  size='large'   prefix={<LockOutlined className="site-form-item-icon" />}   type="password"   placeholder="请输入密码"/>
      </Form.Item>

      <Form.Item>
   <Link to="/register">还没账号？立即注册</Link>
      </Form.Item>

      <Form.Item>
        <Button size='large' type="primary" htmlType="submit" block>
          登录
        </Button>
      </Form.Item>
    </Form>
        </div>
        </div>
    );
}

export default Login;
