import React, { useState, useEffect } from 'react'
import "./css/select.less"
import { fetchstufflist, purchase, setdefaultcheerleader, fetchaccountdata } from '../request/api'
import store from '../store'
import AvatarsModal from '../components/AvatarsModal'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Popconfirm, message } from "antd";

import { useLocation } from 'react-router-dom';
export default function Selectimg() {
  let [imglist, setImglist] = useState([])
  let [gifId, setGifId] = useState([])
  let [page, setPage] = useState(1)
  let [total, setTotal] = useState(18)
  let [mypage, setMypage] = useState(1)
  let [mytotal, setMytotal] = useState(7)
  let [avatarsdialog, setAvatarsdialog] = useState(false)
  let [avatarsdialogdata, setAvatarsdialogdata] = useState({})
  let [mylist, setMylist] = useState([])
  let [MyCheerleaders,setMyCheerleaders]=useState("")
  
  const [username, setusername] = useState("null")
  const location = useLocation();
  useEffect(() => {



    // 监听state的变化
    let unsubscribe = store.subscribe(() => {

      setMyCheerleaders(store.getState().gid)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  useEffect(() => {



    // 监听state的变化
    let unsubscribe = store.subscribe(() => {
      // console.log('监听中..',store.getState().changeGif,)
      setGifId(store.getState().changeGifid)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  useEffect(() => {

    let unsubscribe = store.subscribe(() => {

      setusername(store.getState().inputVal)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  useEffect(() => {

    const currentPath = location.pathname;
    
   
    setMyCheerleaders(currentPath.includes("CLCS"))
    // console.log(currentPath,"aaaaaaaaaaaaaaaaaa",currentPath.includes("CLCS"),MyCheerleaders);
    if (localStorage.hasOwnProperty("gamer_reg") == false) {

      setTimeout(function () {
        fetchimglist()
        fetchmyimglist()
        // 首次进入没有信息延迟调用
        console.log("首次进入没有信息延迟调用");
      }, 200);
    } else {
      // 首次进入有信息直接调用
      console.log("首次进入有信息直接调用");
      fetchimglist()
      fetchmyimglist()
    }


  }, [])
  // 调用接口获取未购买列表
  function fetchimglist() {

    


  
    if(localStorage.hasOwnProperty("gamer_reg")==true){
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
      let UUID = gamer_reg.uuid
      fetchstufflist({
        uuid: UUID,
        type: "cheerleader",
        page: page
      }).then((res) => {
  
 

     
        
        setTotal(res.total_num)
        setImglist(res.data)
        console.log("接口获取到的数据更新至",imglist,res.total_num);
      })
    }else{
      
      setTimeout(()=>{
        let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
        let UUID = gamer_reg.uuid
        fetchstufflist({
          uuid: UUID,
          type: "cheerleader",
          page: page
        }).then((res) => {
    
          setImglist(res.data)
          setTotal(res.total_num)
    
        })
    },1000)
    }



  }
  // 调用接口获取已购买列表
  function fetchmyimglist() {
    if(localStorage.hasOwnProperty("gamer_reg")==true){
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
      let UUID = gamer_reg.uuid
  
      fetchaccountdata({
        uuid: UUID,
        type: "cheerleader",
        page:mypage,
        page_size:7
      }).then((res) => {
  
        if (res.code == "2") {
          let obj = []
          setMylist(obj)
        } else {
          let obj = res.data
          setMylist([...obj])
          setMytotal(res.total_num)
          // console.log(mylist);
        }
  
      })
    }else{
      setTimeout(()=>{
        let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
        let UUID = gamer_reg.uuid
    
        fetchaccountdata({
          uuid: UUID,
          type: "cheerleader",
          page:mypage,
          page_size:7
        }).then((res) => {
    
          if (res.code == "2") {
            let obj = []
            setMylist(obj)
          } else {
            let obj = res.data
            setMylist([...obj])
            setMytotal(res.total_num)
            // console.log(mylist);
          }
    
        })
    },1000)


    }
 

  }

  // 更改头像
  function changeImg(val) {
    setAvatarsdialogdata(val)
    setAvatarsdialog(true)

  }

  function avatarsmodel() {
    fetchimglist()
    fetchmyimglist()
    setAvatarsdialog(false)


  }
  // 购买头像
  const confirm = (e) => {
    console.log(e);
    
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))

    let UUID = gamer_reg.uuid


    // return
    setdefaultcheerleader({
      uuid: UUID,
      stuff_id: e.property_id,
    }).then((res) => {
      console.log(e);

    

      const action5 = {
        type: "changeGif",       // type属性是必须要写的，用于校验
        value: JSON.parse(e.propertycheer.gifurl)[0],          // value代表要修改为什么值
      }
      const action6 = {
        type: "changeGifid",       // type属性是必须要写的，用于校验
        value: e.property_id,          // value代表要修改为什么值
      }
      store.dispatch(action5);
      store.dispatch(action6);

      gamer_reg.cheerleader_gif_url = e.propertycheer.gifurl
      gamer_reg.cheerleader_id = e.property_id + ""
      localStorage.setItem("gamer_reg", JSON.stringify(gamer_reg));
    })
    // message.success('Click on Yes');

  };
  
  function onepage() {
    console.log(page);

    // setPage((data) => {  return 1})
    setMypage((data) =>  {  return 1})
  }
  // 分页上一页
  function changepageleft() {
    console.log(page);

    if (page == 1) {

      message.info('已经是第一页了!')
    } else {
      setPage((data) => { let new_data = data - 1; return new_data })

      // fetchimglist()
    }

  }
  // 分页下一页
  function changepageright() {

    if (page * 18 >= total) {
      message.info('已经是最后一页了!')
    } else {
      setPage((data) => { let new_data = data + 1; return new_data })
    }



  }
  // 已购买图片上一页
  function changemypageleft() {
    console.log(page);

    setMypage((data) => { let new_data = data - 1; return new_data })

  }
  // 已购买图片下一页
  function changemypageright() {

    setMypage((data) => { let new_data = data + 1; return new_data })



  }
  //  监听头像列表
  useEffect(() => {

    if (localStorage.hasOwnProperty("gamer_reg") == true) {
      fetchimglist()
    }
  }, [page])
  //  监听自己头像列表 
  useEffect(() => {

    if (localStorage.hasOwnProperty("gamer_reg") == true) {
      fetchmyimglist()
    }
  }, [mypage])
  return (





    <div className={["select" , (MyCheerleaders=="CLCS" ? 'clcsselect' : '')].join(" ")}  style={{ marginTop: "0px", display: username == "null" ? 'none' : 'block' }}>

      <div className='pageul' style={{ display: imglist.length >0 ? 'flex' : 'none' }}>
        <div style={{ width: "14px" }}>
          <CaretLeftOutlined onClick={changepageleft} style={{ display: page == 1 ? 'none' : 'block' }} />
        </div>

        <ul className='nobuyimg'>

          {imglist.map((item, idx) => (

            <li key={idx} style={{ display: item.file_type == "image" ? 'block' : 'none' }} onClick={() => { changeImg(item) }} >

              <img src={item.url} alt="" />

            </li>



          ))
          }
        </ul>
        <div style={{ width: "14px" }}>
          <CaretRightOutlined onClick={changepageright} style={{ display: page * 18 >= total ? 'none' : 'block' }} />
        </div>

      </div>
      <div className='img-message' style={{ display: imglist.length==  0 ? 'flex' : 'none' }}>
        <ul>
          <h3>You have bought all.</h3>
        </ul>

      </div>
      
      <div className='bt'  style={{ display:MyCheerleaders=="CLCS" ? 'none' : 'block' }} >
       My Cheerleaders
      </div>
      <div className='pageul2'  style={{ display:MyCheerleaders=="CLCS" ? 'none' : 'block' }}>
        
  
        <ul className='buyimg' >
        <div className='left1' style={{ width: "14px" }}>
        {/* style={{ display: mypage == 1 ? 'none' : 'block' }} */}
          <CaretLeftOutlined onClick={changemypageleft}  style={{ display: mypage == 1 ? 'none' : 'block' }}  />
        </div>
        <div className='buyimg-message' style={{ display: mylist.length == 0 ? 'block' : 'none' }}>
        <ul>
          <h3>You have no cheerleaders yet.</h3>
        </ul>

      </div>
        {/* style={{ width: mylist.length * 91 + "px" }} */}
          {mylist.map((item, idx) => (
            // gifId
            // style={{display:item.file_type=="image"&&idx>3?'block':'none'}}
            <div key={idx} style={{ display: mylist.length > 0 ? 'block' : 'none' }} onClick={()=>{confirm(item)}}>
              <li style={{ display: item.property_id == gifId ? 'none' : 'block' }}  >
              <img src={item.propertycheer.url} alt="" />
                {/* <Popconfirm
                  title="Set this cheerleader as the main one"
                  onConfirm={() => confirm(item)}
                  okText="Yes"
                  cancelText="No"
                  color="#fff8e2"
                >
                  <img src={item.propertycheer.url} alt="" />
                </Popconfirm> */}
              </li>
              <li style={{ display: item.property_id == gifId ? 'block' : 'none', background: "#c3e3c3" }}  >
                <img src={item.propertycheer.url} alt="" />
              </li>
            </div>
          ))
          }

<div className='left2' style={{ width: "14px" }}>
        {/* style={{ display: mypage * 7 >= mytotal ? 'none' : 'block' }} */}
          <CaretRightOutlined onClick={changemypageright} style={{ display: mypage * 7 >= mytotal ? 'none' : 'block' }}  />
        </div>
        </ul>


      </div>
      {/* <div  className='bt'>
       My Game Data
      </div> */}
      <AvatarsModal
        xdata={avatarsdialog}
        ydata={avatarsdialogdata}
        gifsetPare={avatarsmodel}
        onepage={onepage}
        type="cheerleader"
      ></AvatarsModal>
    </div>

  )
}
