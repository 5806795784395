import axios from 'axios'

// 配置项
if (window.domin == "{$domin}") {
    var urll = '/api'

    console.log("走上边", window.domin);
} else {
    var urll = 'https://' + window.domin + '/api'
    console.log("走下边", 'https://' + window.domin + '/api');
}
const axiosOption = {
    baseURL: urll,
    timeout: 50000,
    headers: { 'Content-Type': 'application/json' }
}

// 创建一个单例
const instance = axios.create(axiosOption);

// 添加请求拦截器
instance.interceptors.request.use(function(config) {
    return config;
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function(response) {
    // 对响应数据做点什么
    return response.data;
}, function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default instance;