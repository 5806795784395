import React, { useState, useEffect } from 'react'

import "./css/AvatarsModal.less"
import { Button, Modal, message, Space } from "antd";

import { purchase } from "../request/api"
import store from '../store'
import { UnlockOutlined } from '@ant-design/icons';

export default function Model(props) {
  // const state = store.getState()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [credits, setcredits] = useState(0)

  useEffect(() => {



    // 监听state的变化
    let unsubscribe2 = store.subscribe(() => {
      // console.log('监听中..',store.getState().changeCredit,)
      setcredits(store.getState().changeCredit)
    })
    return () => {
      // 取消监听
      unsubscribe2();
    }
  }, [])
  const handleOk = () => {


    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
    let UUID = gamer_reg.uuid
    purchase({
      uuid: UUID,
      stuff_id: data.id,
      type: props.type
    }).then((res) => {
      props.onepage()
      if (res.code == "2") {
        message.info(res.msg);
      } else {
        const action10 = {
          type: "cheernum",       // type属性是必须要写的，用于校验
          value: res.data.cheer_num,          // value代表要修改为什么值
          show:true
        }
        // 将action用dispatch方法传递给store
        store.dispatch(action10);
        console.log(res);
        if (props.type == "avatar") {
          //这是更换头像
          let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
          gamer_reg.total_credits = res.data.total_credits_left + ""

          const action2 = {
            type: "changeCredits",       // type属性是必须要写的，用于校验
            value: res.data.total_credits_left,          // value代表要修改为什么值
          }

          const action3 = {
            type: "changeAvatar",       // type属性是必须要写的，用于校验
            value: res.data.current_avatar_url,          // value代表要修改为什么值
          }


          gamer_reg.avatar_url = res.data.current_avatar_url + ""

          const action4 = {
            type: "changeAvatarid",       // type属性是必须要写的，用于校验
            value: res.data.current_avatar_id,          // value代表要修改为什么值
          }
          // 将action用dispatch方法传递给store
          store.dispatch(action4);
          store.dispatch(action2);
          store.dispatch(action3);
          // current_avatar_id
          gamer_reg.avatar_id = res.data.current_avatar_id + ""
          localStorage.setItem("gamer_reg", JSON.stringify(gamer_reg));
          props.setPare();
        } else {
          //这是更换背景
          let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
          gamer_reg.total_credits = res.data.total_credits_left + ""
          const action2 = {
            type: "changeCredits",       // type属性是必须要写的，用于校验
            value: res.data.total_credits_left,          // value代表要修改为什么值
          }
          const action5 = {
            type: "changeGif",       // type属性是必须要写的，用于校验
            value: JSON.parse(res.data.current_cheerleader_gif_url)[0],          // value代表要修改为什么值
          }
          const action6 = {
            type: "changeGifid",       // type属性是必须要写的，用于校验
            value: res.data.current_cheerleader_id,          // value代表要修改为什么值
          }
          // 将action用dispatch方法传递给store
          store.dispatch(action5);
          store.dispatch(action6);
          store.dispatch(action2);
          // current_avatar_id
          gamer_reg.cheerleader_gif_url = res.data.current_cheerleader_gif_url
          gamer_reg.cheerleader_id = res.data.current_cheerleader_id + ""
          localStorage.setItem("gamer_reg", JSON.stringify(gamer_reg));
          props.gifsetPare()
        }

      }

    })


  };


  const handleCancel = () => {
    setData("")
    if (props.type == "avatar") {
      props.setPare();
    } else {
      props.gifsetPare()
    }

  };
  const onFinish = (values) => {
    console.log("登录");
    props.setPare();

    //这里调接口购买头像





  };

  useEffect(() => {
    setIsModalOpen(props.xdata);
    setData(props.ydata)
    // console.log(data);
  })



  return (
    <div className='AvatarsModal'>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <div className='model_img'>
          <img style={{ width: "50%" }} src={data.url_main} alt="" />
          <div className='model_img_right'>
            <p><span style={{ color: "green", marginLeft: "10px", borderRight: "none" }}>Getting this costs: </span></p>
            <p style={{ color: "rgb(189, 141, 39)", marginLeft: "10px", borderRight: "none" }}>{data.credit_price}<img src={require('../assets/img/credit.png')} alt="" /></p>
{/* <p>
  图片积分{data.credit_price}我的积分{credits}
  比大小{data.credit_price-credits}
</p> */}

            <p style={{ color: "green", marginLeft: "10px", borderRight: "none",fontSize:"16px",display:data.credit_price-credits<=0?'block':'none' }}>
              
              {/* You have <span style={{ color: "rgb(189, 141, 39)" }}>{credits}</span>  credits in total! */}
              
              Your current balance:
              <br />
              <span style={{ color: "rgb(189, 141, 39)" }}>{parseFloat(credits).toFixed(2)}</span> credits
              </p>

            <p style={{ color: "green", marginLeft: "10px", borderRight: "none",fontSize:"16px" ,display:data.credit_price-credits>0?'block':'none'  }}>
            Your current balance is <span style={{ color: "rgb(189, 141, 39)" }}>{ parseFloat(credits).toFixed(2)}</span> credits.
            Please play games to earn more!
              
              
              {/* You currently have <span style={{ color: "rgb(189, 141, 39)" }}>{credits}</span>  credits. Please play games
to earn more! */}

 </p>

            <div style={{ marginTop: "20px",display:"flex" }}> <Button style={{ marginLeft: "10px",display:data.credit_price-credits<=0?'block':'none'  }} className='form-button' type="primary" onClick={handleOk} >
              Exchange
            </Button>
              <Button style={{ marginLeft: "10px",display:data.credit_price-credits<=0?'block':'none'  }} className='form-button' onClick={handleCancel} >
                Cancel
              </Button>
              <Button style={{ marginLeft: "10px",display:data.credit_price-credits>0?'block':'none' }} type="primary" className='form-button' onClick={handleCancel} >
           OK
              </Button>
              
              </div>



            {/* <p  style={{color:"black",marginLeft:"10px",borderRight:"none"}}> <UnlockOutlined />Use this avatar as current</p> */}
          </div>
        </div>


      </Modal>
    </div>
  )
}
