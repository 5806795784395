//

import App from "../App";
import List from "../pages/List";
import DJG from "../pages/DJG";
import PG from "../pages/PG";



import ASG from "../pages/ASG";
import SLG from "../pages/SLG";
import SFG from "../pages/SFG";
import TTTG from "../pages/TTTG";
import SMPG from "../pages/SMPG";
import POG from "../pages/POG";
import TRRG from "../pages/TRRG";
import MSG from "../pages/MSG";
import G from "../pages/2048G";
import FCG from "../pages/FCG";
import WLSG from "../pages/WLSG";

import BDG from "../pages/BDG";
import BBG from "../pages/BBG";
// import BPG from "../pages/BPG";

import CSG from "../pages/CSG";
// import DMG from "../pages/DMG";
// import MFCG from "../pages/MFCG";
// import MCG from "../pages/MCG";
// import MCMG from "../pages/MCMG";
// import NSG from "../pages/NSG";
// import SVBG from "../pages/SVBG";
// import TMG from "../pages/TMG";
// import UTHG from "../pages/UTHG";
// import WSG from "../pages/WSG";
import WSCG from "../pages/WSCG";

// import WN from "../pages/gamedetails";
import CLTG from "../pages/CLTG";
import CLCS from "../pages/CLCS";







import Login from "../pages/Login";
import SG from "../pages/SG";
import Register from "../pages/Register";
import Home from "../pages/Home";
import { HashRouter as Router,Route,Routes} from 'react-router-dom'


const HashRouter=()=>(
    <Router>
    <Routes>
        <Route path="/" element={<App></App>}>
      
        <Route path="/list" element={<List></List>}></Route>
        <Route path="/DJG" element={<DJG></DJG>}></Route>
        <Route path="/PG" element={<PG></PG>}></Route>


        <Route path="/ASG" element={<ASG></ASG>}></Route>
        <Route path="/SLG" element={<SLG></SLG>}></Route>
        <Route path="/SFG" element={<SFG></SFG>}></Route>
        <Route path="/TTTG" element={<TTTG></TTTG>}></Route>
        <Route path="/SMPG" element={<SMPG></SMPG>}></Route>
        <Route path="/POG" element={<POG></POG>}></Route>
        <Route path="/TRRG" element={<TRRG></TRRG>}></Route>
        <Route path="/MSG" element={<MSG></MSG>}></Route>
        <Route path="/2048G" element={<G></G>}></Route>
        <Route path="/FCG" element={<FCG></FCG>}></Route>

        <Route path="/BDG" element={<BDG></BDG>}></Route>
        <Route path="/BBG" element={<BBG></BBG>}></Route>
        <Route path="/CSG" element={<CSG></CSG>}></Route>
        <Route path="/WLSG" element={<WLSG></WLSG>}></Route>
        <Route path="/WSCG" element={<WSCG></WSCG>}></Route>
        <Route path="/CLCS" element={<CLCS></CLCS>}></Route>
        <Route path="/CLTG" element={<CLTG></CLTG>}></Route>
    
        {/* <Route path="/BPG" element={<BPG></BPG>}></Route>

       
        <Route path="/DMG" element={<DMG></DMG>}></Route>
        <Route path="/MFCG" element={<MFCG></MFCG>}></Route>
        <Route path="/MCG" element={<MCG></MCG>}></Route>
        <Route path="/MCMG" element={<MCMG></MCMG>}></Route>
        <Route path="/NSG" element={<NSG></NSG>}></Route>
        <Route path="/SVBG" element={<SVBG></SVBG>}></Route>
        <Route path="/TMG" element={<TMG></TMG>}></Route>
        <Route path="/UTHG" element={<UTHG></UTHG>}></Route>
        <Route path="/WSG" element={<WSG></WSG>}></Route>
 */}



        

{/* <Route path="/wn" element={<WN></WN>}></Route> */}
        <Route path="/SG" element={<SG></SG>}></Route>
        </Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/register" element={<Register></Register>}></Route>
          <Route path="/home" element={<Home></Home>}></Route>
    </Routes>
    </Router>
)

export default HashRouter