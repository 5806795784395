// Bar组件  子组件
import * as echarts from 'echarts'
import { useEffect, useRef } from 'react'
 
import React from 'react';


// // 将用来自定义的提取出来
// const Barr = (props,ref) => {
//   // { title, xData, yData, style }

//   const domRef = useRef()
 
//   useEffect(() => {
//     // document.getElementById("reactecharts").removeAttribute('_echarts_instance_');
//     chartTnit()
//   }, [])
 
//   const chartTnit = () => {
//     // 基于准备好的dom，初始化echarts实例
//     const myChart = echarts.init(domRef.current)

    
//     // 绘制图表
//     console.log("这是折线图", props);
//     myChart.setOption({
//       notMerge:true,
//       title: {
//         show:false,
//         text: props.title,
//         textStyle:{
//           //文字颜色
//           color:'blue',
//           //字体大小
//   fontSize:14
//       },
      
//       },
//       tooltip: {},
//       xAxis: {
//         type: 'category',
//         data: props.xData
//       },
//       yAxis: {},
//       series: [
//         {
//           name: '分数',
//           type: 'line',
//           data: props.yData
//         }
//       ]
//     })
//   }
 
//   return (<div  >
//     {/* 挂载节点 */}
//     <div id='reactechartsb' ref={domRef} style={props.style}></div>
//   </div>)
// }
class Bar extends React.Component {

  componentDidMount() {   //初始化组件，只执行一次
    this.initEcharts();
  }
 componentDidUpdate(){   // 组件更新时也会调用。
    this.initEcharts();
 }
 initEcharts() {     
  const myChart = echarts.init(   
    document.getElementById("mainc") 
  );


  myChart.setOption({
    notMerge:true,
    
    title: {
        text: this.props.title,
        textStyle:{
          //文字颜色
          // color:'blue',
          //字体大小
  fontSize:14
      },
      
      },
      
    xAxis: {
      type: 'category',
      data: this.props.xData
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: this.props.yData,
        name: 'score',
        type: 'bar',
      }
    ],
    tooltip: { show: true }
  })
  }

  render(){
    return (<div  >
      {/* 挂载节点 */}
      <div id='mainc' style={this.props.style}></div>
    </div>)
  }

}


export default Bar