import React, { useEffect, useState } from 'react';
import "./assets/base.less"
import 'antd/dist/reset.css';

import { Outlet } from 'react-router-dom'
import { Layout } from 'antd';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Header from './components/Header'
import Aside from './components/Aside'
import Bread from './components/Bread'
import Footer from './components/Footer'
import Selectimg from './components/Select'
import Infomercial from './components/Infomercial'
import FooterInfomercial from './components/footerInfomercial'
import store from './store'

import { fetchname } from './request/api'
const { Sider, Content } = Layout;
const App = () => {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {

    // 在路由变化时执行的操作


    const url = window.location.href

    // console.log('Route has changed:路由变化了', location.pathname,location, window);
    if (url.indexOf("?ut") != -1) {
      // console.log("地址栏中带有问号ut去除地址栏地址");
      let ut = url.split("?ut=")
      let gid = ut[1].split("&gid=")


      localStorage.setItem("utoken", JSON.stringify(gid[0]));
      localStorage.setItem("gid", JSON.stringify(gid[1]));
      // console.log("这是ut",ut,gid);
      let url1 = url.split("?")[0]
      fetchname({
        utoken: gid[0],

      }).then(
        (res) => {
          const action = {
            type: "changeInputValue",       // type属性是必须要写的，用于校验
            value: res.data.player_name,          // value代表要修改为什么值
        }

        store.dispatch(action);
          //  localStorage.setItem("gamer_reg",JSON.stringify(res.data));
          if (localStorage.hasOwnProperty("gamer_reg") == true) {
            localStorage.removeItem("gamer_reg")
          }
          localStorage.setItem("utoken", JSON.stringify(gid[0]));
 let credits={
  change:true,

 }
          localStorage.setItem("credits", JSON.stringify(credits));

          window.location.href = url1 + "#" + '/' + gid[1]


        })
      return
      if (localStorage.hasOwnProperty("utoken") == false) {
        // 缓存中没有utoken
        localStorage.setItem("utoken", JSON.stringify(gid[0]));
        localStorage.setItem("gid", JSON.stringify(gid[1]));
        // console.log("这是ut",ut,gid);
        let url1 = url.split("?")[0]
        fetchname({
          utoken: gid[0],

        }).then(
          (res) => {



            //  localStorage.setItem("gamer_reg",JSON.stringify(res.data));
            if (localStorage.hasOwnProperty("gamer_reg") == true) {
              localStorage.removeItem("gamer_reg")
            }
            localStorage.setItem("utoken", JSON.stringify(gid[0]));
   


            window.location.href = url1 + "#" + '/' + gid[1]


          })
        // window.location.href = url1 + "#" + '/' + gid[1]
      } else {
        // 缓存中有utoken 比较新的和旧的是否相同
        let url1 = url.split("?")[0]

        fetchname({
          utoken: gid[0],

        }).then(
          (res) => {



            //  localStorage.setItem("gamer_reg",JSON.stringify(res.data));
            if (localStorage.hasOwnProperty("gamer_reg") == true) {
              localStorage.removeItem("gamer_reg")
            }
            localStorage.setItem("utoken", JSON.stringify(gid[0]));
            let url1 = url.split("?")[0]


            window.location.href = url1 + "#" + '/' + gid[1]


          })


      }





    }else{

      
    if(url.charAt(url.length-1)!=="G"&&url.charAt(url.length-1)!=="S"){
      navigate('/'+"WLSG")
    }
      // if(localStorage.hasOwnProperty("gid")==false){
      //   // let gid = JSON.parse(localStorage.getItem("gid"))

      //   // navigate('/'+gid)
      //   navigate('/'+"2048G")
      // }
    }

  }, [location]);

  return (
    <>
      <Layout id='app'>
        <Header></Header>
        <Layout>

          <Content>
            <div className='html-center'>
              <Sider width={240}>
                <Aside></Aside>
              </Sider>

              <div className='container_box'>
                <Bread></Bread>
                <Selectimg></Selectimg>
                <Outlet></Outlet>
              </div>

              <Infomercial></Infomercial>
            </div>
            <FooterInfomercial></FooterInfomercial>
            <Footer></Footer>
          </Content>
        </Layout>
        {/* <footer>


        footer
      </footer> */}
      </Layout>

    </>

  );
}

export default App;
