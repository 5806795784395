// Bar组件  子组件
import * as echarts from 'echarts'
import { useEffect, useRef } from 'react'
 
import React from 'react';


// // 将用来自定义的提取出来
// const Barr = (props,ref) => {
//   // { title, xData, yData, style }

//   const domRef = useRef()
 
//   useEffect(() => {
//     // document.getElementById("reactecharts").removeAttribute('_echarts_instance_');
//     chartTnit()
//   }, [])
 
//   const chartTnit = () => {
//     // 基于准备好的dom，初始化echarts实例
//     const myChart = echarts.init(domRef.current)

    
//     // 绘制图表
//     console.log("这是折线图", props);
//     myChart.setOption({
//       notMerge:true,
//       title: {
//         show:false,
//         text: props.title,
//         textStyle:{
//           //文字颜色
//           color:'blue',
//           //字体大小
//   fontSize:14
//       },
      
//       },
//       tooltip: {},
//       xAxis: {
//         type: 'category',
//         data: props.xData
//       },
//       yAxis: {},
//       series: [
//         {
//           name: '分数',
//           type: 'line',
//           data: props.yData
//         }
//       ]
//     })
//   }
 
//   return (<div  >
//     {/* 挂载节点 */}
//     <div id='reactechartsb' ref={domRef} style={props.style}></div>
//   </div>)
// }
class Bar extends React.Component {

  componentDidMount() {   //初始化组件，只执行一次
    this.initEcharts();
  }
 componentDidUpdate(){   // 组件更新时也会调用。
    this.initEcharts();
 }
 initEcharts() {     
  const myChart = echarts.init(   
    document.getElementById("mainb") 
  );
  let xNum=this.props.xData.length-2
  // console.log("xnum",xNum);

  myChart.setOption({

    // toolbox: {
    //   show: true,
    //   feature: {
    //     mark: { show: true },
    //     dataView: { show: true, readOnly: false },
    //     restore: { show: true },
    //     saveAsImage: { show: true }
    //   }
    // },
    notMerge:true,
    title: {
      text: this.props.title,
      textStyle:{
        //文字颜色
        // color:'blue',
        //字体大小
fontSize:14
    },
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    // xAxis: {
    //   type: 'category',
    //   data: this.props.xData,
      
    //   // axisLabel: {
    //   //   interval: xNum // 设置每隔 2 个标签显示一个标签
    //   // }
    // },
    // yAxis: {},
    series: [
    
      {
        name: 'score',
        type: 'pie',
        data: this.props.xData,
        type: 'pie',
        radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center',
        formatter:"proportion: {d}%"
    
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: true
      },
      }
    ]
  })
  }

  render(){
    return (<div  >
      {/* 挂载节点 */}
      <div id='mainb' style={this.props.style}></div>
    </div>)
  }

}


export default Bar