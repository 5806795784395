// import ReactDom from 'react-dom'
// import App from './App'

// import { createRoot } from 'react-dom/client';
// // import {Provider} from 'react-redux'
// import store from "./store"
import Router from './router'

// const root = createRoot(document.getElementById("root"));

// root.render(

//     <Router store={store}>

// </Router>
// ,

// );
// ReactDom.render(
// <Router store={store}>

// </Router>
// ,
// document.getElementById('root')


// )

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import App from './App'
import store from "./store"
import {persistor} from './store'
import {PersistGate} from 'redux-persist/lib/integration/react';
ReactDOM.render(<Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
    <Router store={store}>

</Router>
            </PersistGate>
        </Provider>, document.getElementById('root'));