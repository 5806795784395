import React,{useState,useEffect} from 'react'
import "./css/footerInfomercial.less"

export default function Infomercial() {





  return (
 <div className='footerInfomercial'>
<div className="footerInfomercial_img"> <img  src={require('../assets/img/advertisement/advertisement4.jpg')} alt="" /></div>
 

 </div>
  )
}
