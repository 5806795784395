import React,{useEffect,useState} from 'react'
import {useNavigate,useLocation,Link} from 'react-router-dom'
import { Menu,Input, Space  } from 'antd';
import eosb from '../assets/img/logo/2048.png'
import WLSG from '../assets/img/logo/wall-smash.png'
import doodle from '../assets/img/logo/doodle-jump.png'
import pacman from '../assets/img/logo/pacman.png'
import apple from '../assets/img/logo/apple-snake.png'
import snake from '../assets/img/logo/snake-like.png'
import space from '../assets/img/logo/space-fighter.png'
import tic from '../assets/img/logo/tic-tac-toe.png'
import pong from '../assets/img/logo/pong.png'
import trex from '../assets/img/logo/trex-customizable-runner.png'
import minesweeper from '../assets/img/logo/minesweeper-game.png'
import snake2 from '../assets/img/logo/snake.png'

import star from '../assets/img/logo/star-crush.png'
import fruiticon from '../assets/img/logo/fruiticon.png'

import balloon from '../assets/img/logo/balloon-defense.png'
import balloonpop from '../assets/img/logo/balloon-pop.png'
import brick from '../assets/img/logo/brick-break.png'
import color from '../assets/img/logo/color-snake.png'
import drag from '../assets/img/logo/drag-maze.png'
import math from '../assets/img/logo/math-flashcard.png'
import memory from '../assets/img/logo/memory-card.png'
import motion from '../assets/img/logo/motion-card-match.png'
import number from '../assets/img/logo/number-snake.png'
import svbg from '../assets/img/logo/snake-vs-block.png'
import triangle from '../assets/img/logo/triangle-match.png'
import unique from '../assets/img/logo/unique-triangle-hunt.png'
import word from '../assets/img/logo/word-scramble.png'
import ws from '../assets/img/logo/word-search.png'
import CLTG from '../assets/img/logo/cltg.png'
import CLCS from '../assets/img/logo/CLCS.png'
import featuredicon from '../assets/img/logo/featuredicon.png'
import store from '../store'

import {fetchname} from '../request/api'
import './css/aside.less'
const { Search } = Input;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [

  // getItem('Map Styles', 'list', null, [
  //   getItem('Option 1', '1'),
  //   getItem('Option 2', '2'),
  //   getItem('Option 3', '3'),
  //   getItem('Option 4', '4'),
  // ]),
  getItem('Doodle Jump Game', 'DJG'),
  getItem('Pacman Game', 'PG'),
  getItem('Apple Snake Game', 'ASG'),
  getItem('Snake-like Game', 'SG'),
  getItem('Space Fighter Game', 'edit'),
  getItem('Tic-Tac-Toe Game', 'Pacman'),
  getItem('Star Match Puzzle Game', 'edit'),
  getItem('Pong Game', 'Pacman'),
  getItem('T-Rex Runner Game	', 'edit'),
  getItem('Minesweeper Game	', 'Pacman'),
  getItem('2048 Game', 'edit'),
  getItem('Snake Game', 'Pacman'),
//  


];
export default function Aside() {
const navigate=useNavigate()
const location=useLocation()
// const [defaulKey,setDefaulKey]=useState('')
const [selectrouter,setSelectrouter]=useState('DJG')
const onSearch = (value) => console.log(value);
useEffect(()=>{
  // let UT=window.utoken
  // console.log(UT);
  // localStorage.setItem("utoken",UT);
  // if(UT=="{$utoken}"){
  //   UT="c351812e7f74be6342d297ad02a7840d96be1e28d9840db343169e10814f7"
  //   console.log(UT);
  //   localStorage.setItem("utoken",UT);
  // }
  // if(UT=="none"){
  //   let obj={"player_name":"测试","gamer_reg":{"DJG":"","PG":"","ASG":"","SLG":"","SFG":"","TTTG":"","SMPG":"","POG":"","TRRG":"","MSG":"","2048G":"","SG":""}}
  //   let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
  //   if(gamer_reg==null){
  //    localStorage.setItem("gamer_reg",JSON.stringify(obj));
  //    navigate(location.pathname)
  //   }else{
  //    localStorage.setItem("gamer_reg",JSON.stringify(obj));
  //   }
  // }else{
  //   fetchname({
  //     utoken: UT,
   
  //   }).then(
  //     (res)=>{
  //  console.log(res);
  //  console.log("判断有没有token",localStorage.hasOwnProperty("gamer_reg"));
  //  if(localStorage.hasOwnProperty("gamer_reg")==false){
  //   localStorage.setItem("gamer_reg",JSON.stringify(res.data));
  //   navigate(location.pathname)
  //  }else{
  //   localStorage.setItem("gamer_reg",JSON.stringify(res.data));
  //  }
  
  
  
  //     })
  // }
 

  let path=location.pathname
  console.log(window.gid,"调用了更改gid",window.utoken,path.split("/"));

  setSelectrouter(path.split("/")[1])
 
  // let key=path.split('/')[1]
  // setDefaulKey(key)
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[location])




  function changePush(e){
    setSelectrouter(e)
    window.gid=e
    console.log(window.gid);
    const action4 = {
      type: "changeGid",       // type属性是必须要写的，用于校验
      value: e,          // value代表要修改为什么值
    }
      
      store.dispatch(action4);
    navigate('/'+e)
    return

    const url = window.location.href
    
    setSelectrouter(e)
    if(url.indexOf("?ut") != -1){
      console.log("地址栏中带有问号ut去除地址栏地址");
      let url1=url.split("?")[0]
      let url2=url.split("#")[1]
      console.log(url1+"#"+url2);
      window.location.href=url1+"#"+url2
    
      console.log(e,window.gid);
      
      navigate('/'+e)
    }else{
     
      console.log(e,window.gid);
    
      navigate('/'+e)
    }


    
 
 

  

   
 
  
  }
  return (
    <div className='asdie-all'>

   {/* <Search
      placeholder="Search"
      onSearch={onSearch}
      style={{
        width: 140,margin:30,
      }}
    /> */}


    {/* <Menu
    onClick={onClick}
    style={{ 
      width: 160,
    }}
   selectedKeys={[defaulKey]}
    defaultOpenKeys={['sub1']}
    mode="inline"
    items={items}
    className='aside'
  /> */} 
  <ul className='aside-menu'>
  <div className='asdie-header'>
          <img src={featuredicon} alt="" />
          <span>Featured</span>
        </div>
          <li onClick={()=>{changePush('CLCS')}}  style={{background:selectrouter=="CLCS"?"#E6F4FF":"white"}}><img src={CLCS} alt="" /> <span>Cheer Cursor</span></li>
  {/* <li onClick={()=>{changePush('CLTG')}}  style={{background:selectrouter=="CLTG"?"#E6F4FF":"white"}}><img src={CLTG} alt="" /> <span>Cheer Team Game</span></li> */}
  <div className='divider'></div>
  {/* <li onClick={()=>{changePush('wn')}}  style={{background:selectrouter=="SG"?"#E6F4FF":"white"}}><img src={snake2} alt="" /> <span>wwwwwww</span></li> */}
  {/* <li onClick={()=>{changePush('SG')}}  style={{background:selectrouter=="SG"?"#E6F4FF":"white"}}><img src={snake2} alt="" /> <span>Snake Game</span></li> */}
  {/* <li onClick={()=>{changePush('2048G')}}  style={{background:selectrouter=="2048G"?"#E6F4FF":"white"}}><img src={eosb} alt="" /> <span>2048 Game</span></li> */}
  {/* <li onClick={()=>{changePush('ASG')}}  style={{background:selectrouter=="ASG"?"#E6F4FF":"white"}}><img src={apple} alt="" /> <span>Apple Snake Game</span></li> */}
  {/* <li onClick={()=>{changePush('CSG')}}  style={{background:selectrouter=="CSG"?"#E6F4FF":"white"}}><img src={color} alt="" /> <span>Color Snake Game</span></li> */}
  <li onClick={()=>{changePush('WLSG')}}  style={{background:selectrouter=="WLSG"?"#E6F4FF":"white"}}><img src={WLSG} alt="" /> <span>Wall Smash Game</span></li>
  {/* <li onClick={()=>{changePush('BBG')}}  style={{background:selectrouter=="BBG"?"#E6F4FF":"white"}}><img src={brick} alt="" /> <span>Brick Break Game</span></li> */}
  {/* <li onClick={()=>{changePush('FCG')}}  style={{background:selectrouter=="FCG"?"#E6F4FF":"white"}}><img src={fruiticon} alt="" /> <span>Fruit Collect Game</span></li> */}
    {/* <li onClick={()=>{changePush('DJG')}}  style={{background:selectrouter=="DJG"?"#E6F4FF":"white"}}><img src={doodle} alt="" /> <span>Doodle Jump Game</span></li>
    <li onClick={()=>{changePush('PG')}}   style={{background:selectrouter=="PG"?"#E6F4FF ":"white"}}><img src={pacman} alt="" /> <span>Pacman Game</span></li> */}
 
    {/* <li onClick={()=>{changePush('SLG')}}  style={{background:selectrouter=="SLG"?"#E6F4FF":"white"}}><img src={snake} alt="" /> <span>Snake-like Game</span></li>
    <li onClick={()=>{changePush('SFG')}}  style={{background:selectrouter=="SFG"?"#E6F4FF":"white"}}><img src={space} alt="" /> <span>Space Fighter Game</span></li>
    <li onClick={()=>{changePush('TTTG')}}  style={{background:selectrouter=="TTTG"?"#E6F4FF":"white"}}><img src={tic} alt="" /> <span>Tic-Tac-Toe Game</span></li>
    <li onClick={()=>{changePush('SMPG')}}  style={{background:selectrouter=="SMPG"?"#E6F4FF":"white"}}><img src={star} alt="" /> <span>Star Match Puzzle Game</span></li>
    <li onClick={()=>{changePush('POG')}}  style={{background:selectrouter=="POG"?"#E6F4FF":"white"}}><img src={pong} alt="" /> <span>Pong Game</span></li> */}

    {/* <li onClick={()=>{changePush('TRRG')}}  style={{background:selectrouter=="TRRG"?"#E6F4FF":"white"}}><img src={trex} alt="" /> <span>T-Rex Runner Game</span></li> */}


    {/* <li onClick={()=>{changePush('MSG')}}  style={{background:selectrouter=="MSG"?"#E6F4FF":"white"}}><img src={minesweeper} alt="" /> <span>Minesweeper Game</span></li> */}

    {/* <li onClick={()=>{changePush('BDG')}}  style={{background:selectrouter=="BDG"?"#E6F4FF":"white"}}><img src={balloon} alt="" /> <span>Balloon Defense Game</span></li> */}
    {/* <li onClick={()=>{changePush('BPG')}}  style={{background:selectrouter=="BPG"?"#E6F4FF":"white"}}><img src={balloonpop} alt="" /> <span>Balloon Pop Game</span></li>
    <li onClick={()=>{changePush('BBG')}}  style={{background:selectrouter=="BBG"?"#E6F4FF":"white"}}><img src={brick} alt="" /> <span>Brick Break Game</span></li>
    <li onClick={()=>{changePush('CSG')}}  style={{background:selectrouter=="CSG"?"#E6F4FF":"white"}}><img src={color} alt="" /> <span>Color Snake Game</span></li>
    <li onClick={()=>{changePush('DMG' )}}  style={{background:selectrouter=="DMG"?"#E6F4FF":"white"}}><img src={drag} alt="" /> <span>Drag Maze Game</span></li>
    
    <li onClick={()=>{changePush('MFCG')}}  style={{background:selectrouter=="MFCG"?"#E6F4FF":"white"}}><img src={math} alt="" /> <span>Match Flash Card Game</span></li>
    <li onClick={()=>{changePush('MCG')}}  style={{background:selectrouter=="MCG"?"#E6F4FF":"white"}}><img src={memory} alt="" /> <span>Memory Card Game</span></li>
    <li onClick={()=>{changePush('MCMG')}}  style={{background:selectrouter=="MCMG"?"#E6F4FF":"white"}}><img src={motion} alt="" /> <span>Motion Carc Match Game</span></li>
    <li onClick={()=>{changePush('NSG')}}  style={{background:selectrouter=="NSG"?"#E6F4FF":"white"}}><img src={number} alt="" /> <span>Number Snake Game</span></li>
    <li onClick={()=>{changePush('SVBG')}}  style={{background:selectrouter=="SVBG"?"#E6F4FF":"white"}}><img src={svbg} alt="" /> <span>Snake VS Block Game</span></li>
    
    <li onClick={()=>{changePush('TMG')}}  style={{background:selectrouter=="TMG"?"#E6F4FF":"white"}}><img src={triangle} alt="" /> <span>Trangle Match Game</span></li>
    <li onClick={()=>{changePush('UTHG')}}  style={{background:selectrouter=="UTHG"?"#E6F4FF":"white"}}><img src={unique} alt="" /> <span>Unique Trangle Hunt Game</span></li>
    <li onClick={()=>{changePush('WSG')}}  style={{background:selectrouter=="WSG"?"#E6F4FF":"white"}}><img src={word} alt="" /> <span>Word Scramble Game</span></li> */}
    <li onClick={()=>{changePush('WSCG')}}  style={{background:selectrouter=="WSCG"?"#E6F4FF":"white"}}><img src={ws} alt="" /> <span>Word Search Game</span></li>
{/* <li  style={{background:selectrouter=="BDG"?"#E6F4FF":"white"}}><img src={balloon} alt="" /> <span>Balloon Defense Game</span></li>
<li  style={{background:selectrouter=="BPG"?"#E6F4FF":"white"}}><img src={balloonpop} alt="" /> <span>Balloon Pop Game</span></li>
    <li  style={{background:selectrouter=="BBG"?"#E6F4FF":"white"}}><img src={brick} alt="" /> <span>Brick Break Game</span></li>
    <li  style={{background:selectrouter=="CSG"?"#E6F4FF":"white"}}><img src={color} alt="" /> <span>Color Snake Game</span></li>
    <li   style={{background:selectrouter=="DMG"?"#E6F4FF":"white"}}><img src={drag} alt="" /> <span>Drag Maze Game</span></li>
    
    <li  style={{background:selectrouter=="MFCG"?"#E6F4FF":"white"}}><img src={math} alt="" /> <span>Match Flash Card Game</span></li>
    <li  style={{background:selectrouter=="MCG"?"#E6F4FF":"white"}}><img src={memory} alt="" /> <span>Memory Card Game</span></li>
    <li  style={{background:selectrouter=="MCMG"?"#E6F4FF":"white"}}><img src={motion} alt="" /> <span>Motion Carc Match Game</span></li>
    <li   style={{background:selectrouter=="NSG"?"#E6F4FF":"white"}}><img src={number} alt="" /> <span>Number Snake Game</span></li>
    <li   style={{background:selectrouter=="SVBG"?"#E6F4FF":"white"}}><img src={svbg} alt="" /> <span>Snake VS Block Game</span></li>
    
    <li   style={{background:selectrouter=="TMG"?"#E6F4FF":"white"}}><img src={triangle} alt="" /> <span>Trangle Match Game</span></li>
    <li   style={{background:selectrouter=="UTHG"?"#E6F4FF":"white"}}><img src={unique} alt="" /> <span>Unique Trangle Hunt Game</span></li>
    <li  style={{background:selectrouter=="WSG"?"#E6F4FF":"white"}}><img src={word} alt="" /> <span>Word Scramble Game</span></li>
    <li  style={{background:selectrouter=="WSCG"?"#E6F4FF":"white"}}><img src={ws} alt="" /> <span>Word Search Game</span></li> */}
  <div className='divider'></div>
  </ul>

  {/* <div className='social'>
    <img src="https://www.dragmaze.com/wp-content/plugins/zilla-social/images/16px/Facebook.png" alt="" />
    <img src="https://www.dragmaze.com/wp-content/plugins/zilla-social/images/16px/Instagram.png" alt="" />
    <img src="https://www.dragmaze.com/wp-content/plugins/zilla-social/images/16px/Pinterest.png" alt="" />
    <img src="https://www.dragmaze.com/wp-content/plugins/zilla-social/images/16px/YouTube.png" alt="" />
  </div> */}
    </div>
   
  )
}
