import React, { useState, useEffect,useRef  } from 'react';
import './css/edit.less'

import Bar from '../components/Bar/index'
import bus from '../components/bus' 
import { Carousel, Button } from "antd";
import { ShareAltOutlined,LeftOutlined, RightOutlined } from '@ant-design/icons';
import {Score,fetchname} from '../request/api'
import store from '../store'


 
const Edit = () => {

  const [utoken, setUtoken] = useState("a32bb94563137eea98f1fd09e1ddcb6b6686ae39b7e10cc541231654b1bf4")
  const [gid, setGid] = useState("TRRG")
  const contentStyle = {
    height: "400px",
    lineHeight: "400px",
    textAlign: "center",
    background: "#364d79",
  };
  const carouselEL = useRef(null);
const [show, setShow] = useState(null)
const [resscore, setResscore] = useState({game_name:"1"})
let [time,setTime]=useState([1])
let [scores,setScores]=useState([1])
let [comscore,setComscore]=useState([])
let [topscore,setTopscore]=useState([])
let [playtotal,setPlaytotal]=useState(0)
const [data, setData] = useState([
{
time:"10",
count:10,
paiming:true
},
{
  time:"20",
  count:20,
  paiming:true
  },{
    time:"30",
    count:30,
    paiming:true
    },
]);

useEffect(() => {
if(localStorage.hasOwnProperty("utoken")==false){
  setShow(false)
  return
}
let UT=JSON.parse(localStorage.getItem("utoken"))

// if(UT=="{$utoken}"){
//   UT="c351812e7f74be6342d297ad02a7840d96be1e28d9840db343169e10814f7"
//   console.log(UT);
//   localStorage.setItem("utoken",UT);
// }else if(UT=="none"){
//   setShow(false)
//   return
// }

// 进入子组件 首先判断缓存中有没有游戏数据
if(localStorage.hasOwnProperty("gamer_reg")==false){
  // 缓存中没有游戏数据 调用接口获取
  console.log("首次进入 缓存中没有游戏数据 调用接口获取");
  fetchname({
    utoken: UT,
 
  }).then(
    (res)=>{
 console.log(res);
 localStorage.setItem("gamer_reg",JSON.stringify(res.data));

 scoresShow()

    })
}else{
  scoresShow()
  console.log("缓存中有游戏数据");
}








}, [])
function scoresShow(){
let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
let UT=JSON.parse(localStorage.getItem("utoken"))
 if(gamer_reg.gamer_reg[gid]=="reg"){

  setShow(true)
  Score({
    utoken: UT,
    gid: gid
  }).then(
    (res)=>{
      console.log("该传给子组件");
      // bus.emit('sendVal',res.data.player_name)
      const action = {
        type: "changeInputValue",       // type属性是必须要写的，用于校验
        value: res.data.player_name,          // value代表要修改为什么值
    }
    // 将action用dispatch方法传递给store
    store.dispatch(action);
      setResscore(res.data)
      // console.log(res.data.community_data.com_score_phases,JSON.parse(res.data.community_data.com_score_phases));
      setComscore(JSON.parse(res.data.community_data.com_score_phases))
      setPlaytotal(res.data.community_data.com_total_times)
      setTopscore(JSON.parse(res.data.community_data.top_players))
      console.log("高端玩家信息",JSON.parse(res.data.community_data.top_players));
      let uscores=[]
      let utime=[]
  for(let i=0;i<res.data.scores.length;i++){
 
    uscores.push(res.data.scores[i].score)
    utime.push(res.data.scores[i].addtime)
  }
  scores=uscores
  time=utime
  setScores([...scores])
  setTime([...time])
  // console.log(uscores,scores,time,"11");
    })
}else{
  setShow(false)
}
}


return (
    <div className='Edit'>
       
       <div className='edit-mark' style={{display:show==true?'block':'none'}}> 
             {/* <div className='edit-header'>
            <div className='left'> <ShareAltOutlined />
        
        <span className='smallfont'>SHARE</span></div>
            <div className='right'>
         
        
        <span className='smallfont-right'>Enter Reading Mode</span>
        <ShareAltOutlined />
                   
  
            
            </div>



       
        </div> */}
        <div className='edit-center'>
          <div><div className='edit-img'>
            {/* <h4>Game pictures:</h4> */}
                <img  src={require('../assets/img/thumbnails/trex-customizable-runner.png')} alt="" />
                <div className='edit-mark2'>
            <ul className='Personal-Data'>
            <li className='li1'>{resscore.game_name}</li>
            <h4>Personal Information:</h4>
                <li>Current score: <span style={{color:"blue",marginLeft:"20px"}}>{resscore.last_score}</span> </li>    
                  <li>Max score: <span style={{color:"green",marginLeft:"20px"}}>{resscore.max_score}</span> </li>
                    <li>Play times:<span style={{color:"#BD8D27",marginLeft:"20px"}}>{resscore.total_times}</span></li>
                
                   
                </ul>
       
            </div>
            <div className='edit-mark3'>
          

            <h4>Top Players:</h4>
            <table className="edit-table2">
            <thead>

<tr>
    <th className='th1'>Rank</th>
    <th className='th2'>Players</th>
    <th className='th3'>Score </th>
</tr>
</thead>
<tbody  >
                {topscore.map((item, idx) => (
    <tr key={idx} className={['edit-tr',idx==0?'className0':null,idx==1?'className1':null,idx==2?'className2':null].join(' ')}  style={{display:idx<5?'table-row':'none'}}>
      <td  className='td1'>{idx+1} </td>
<td  className='td2'>{item.player_name}</td>
<td className='td3'>{item.score}</td>
    </tr>

))
}
</tbody>   

            </table>
            
                   
            
       
            </div>
            </div>
            
            
            </div>
            <div>
            <h4 className='table-h4'>Community Performance:  <span className='playtotal'>{playtotal}  Plays</span></h4>
        <table className="edit-table">
    
                <thead>

                    <tr>
                        <th className='th1'>Scores</th>
                        <th className='th2'>Statistics</th>
                        <th className='th3'>Your Rank</th>
                    </tr>
                </thead>
                <tbody>
                {comscore.map((item, idx) => (
      
           <tr key={idx} className="edit-tr">
           <td  className='td1'>{item.score}</td>
           <td className='td2'>
            <div className='td2-div' style={{ width: item.num/playtotal*100 + "%" }}></div>
            <span>count:{item.num} | {Math.trunc(item.num/playtotal*100) }%</span>
            
           </td>
           <td className='td3'>
            <div style={{display:resscore.last_score==item.score?'block':'none'}}> <img src="https://www.dragmaze.com/custom/images/good.png" alt="" /></div>
           </td>
        
       </tr>
    ))
}
             
              
                </tbody>
            </table>
            </div>
         
        
            <Bar
      title='History scores:'
      xData={time}
      yData={scores}
      style={{ width: '700px', height: '500px',float:"left" }} />
     
        </div>

     
        </div>
        {/* 这是没有玩过的游戏 */}
      <div className='edit-details'  style={{display:show==false?'block':'none'}}>
     <div className='details-center'>
        <h4>1. Introduction</h4>
        <p>This chrome extension allows you to play running dino game with customizable theme.</p>
         <hr />       
         <h4>2. Installation</h4>
        <p>You can download and install it from Chrome store:</p>
        <a href="https://chrome.google.com/webstore/detail/t-rex-customizable-runner/pfmcmabmnhnbmkeopkhigmogllbeclbh">
        https://chrome.google.com/webstore/detail/t-rex-customizable-runner/pfmcmabmnhnbmkeopkhigmogllbeclbh

        </a>
         <hr />     
         <h4>3. Key Features</h4>
     <ul>
        <li> If you like Chrome’s dinosaur game, you may like this extension. This extension provides new themes (and characters) for more fun. You can change the theme anytime you want!</li>
      

     </ul>
         <hr />    
         <h4>4. How to Play</h4>
     <ul>
      <li> To start the game, just click in the toolbar icon and then press Space key in the popup window. The controls are the same with dinosaur game. Just press up/space to jump and down to duck for passing obstacles.</li>
      <li>To switch the theme, just click on theme icon at the bottom of game window and selected theme will be loaded for you immediately. There are four themes available for now.</li>
      
     </ul>
         <hr />   
         <h4>5. Screenshots</h4>
         <div className='zmd'>
         <Button
        className="leftButton"
        style={{ left: 55 }}
        onClick={() => {
          carouselEL.current.prev();
        }}
        icon={<LeftOutlined />}
      ></Button>
      <Button
        className="rightButton"
        style={{ right: 55 }}
        onClick={() => {
          carouselEL.current.next();
        }}
        icon={<RightOutlined />}
      ></Button>


      <Carousel
        autoplay
        
        ref={carouselEL}
      >
        <div>
          <h3 style={contentStyle}>
            <div className="img">
           <img src={require('../assets/img/screenshots/trex-customizable-runner/runner-screenshot-1.png')} alt="" />
            </div>
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>   <div className="img">
           <img src={require('../assets/img/screenshots/trex-customizable-runner/runner-screenshot-2.png')} alt="" />
            </div></h3>
        </div>
        <div>
          <h3 style={contentStyle}>   <div className="img">
           <img src={require('../assets/img/screenshots/trex-customizable-runner/runner-screenshot-3.png')} alt="" />
            </div></h3>
        </div>
        <div>
          <h3 style={contentStyle}>   <div className="img">
           <img src={require('../assets/img/screenshots/trex-customizable-runner/runner-screenshot-4.png')} alt="" />
            </div></h3>
        </div>
      
      </Carousel>
    </div>

     </div>

      </div>
        </div>
    );
}

export default Edit;
