import React from "react";
import { Link } from "react-router-dom";
import withRouter from "./withrouter"
import { Breadcrumb } from "antd";
import "./css/bread.less"
const breadcrumbNameMap = {//跟路由路径保持一致
    "/home": "Home",
    "/403": "403",
    "/404": "404",
    "/PG": "Pacman Game",
    "/DJG": "Doodle Jump Game",
    "/WLSG": "Wall smash game",
    "/ASG": "Apple Snake Game",
    "/SLG": "Snake-like Game",
    "/SFG": "Space Fighter Game",
    "/TTTG": "Tic-Tac-Toe Game",
    "/SMPG": "Star Match Puzzle Game",
    "/POG": "Pong Game",
    "/TRRG": "T-Rex Runner Game",
    "/MSG": "Minesweeper Game",
    "/2048G": "2048 Game",
    "/BBG": "Brick Break Game",
    "/CSG": "Color Snake Game",
    "/FCG": "Fruit Collect Game",
    "/SG": "Snake Game",
    '/BDG': 'Balloon Defense Game',
        '/BPG': 'Balloon Pop Game',
        '/BBG': 'Brick Break Game',
        '/CSG': 'Color Snake Game',
        '/DMG': 'Drag Maze Game',
        '/MFCG': 'Match Flash Card Game',
        '/MCG': 'Memory Card Game',
        '/MCMG': 'Motion Card Match Game',
        '/NSG': 'Number Snake Game',
        '/SVBG': 'Snake VS Block Game',
        '/TMG': 'Trangle Match Game',
        '/UTHG': 'Unique Trangle Hunt Game',
        '/WSG': 'Word Scramble Game',
        '/WSCG': 'Word Search Game',
        '/CLTG': 'Cheer Team Game',
         '/CLCS': 'Cheer Cursor'
};

const Nav = withRouter((props) => {
    const { location } = props;
    const pathSnippets = location.pathname.split("/").filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}></Link>
                {breadcrumbNameMap[url]}
            </Breadcrumb.Item>
        );
    });
    const breadcrumbItems = [
        <Breadcrumb.Item key="home"><Link to="/home">Home</Link></Breadcrumb.Item>
    ].concat(extraBreadcrumbItems);
    return (
        <div className="demo">
            <Breadcrumb >
                {breadcrumbItems}
            </Breadcrumb>
        </div>
    );
});
export default Nav;

