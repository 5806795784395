import React, { useState, useEffect, useRef } from 'react';
import './css/edit.less'

import Bar from '../components/Bar/index'
import Bar_b from '../components/Bar/index_B'
import Bar_c from '../components/Bar/index_C'
import bus from '../components/bus'
import Modal from '../components/Modal'
import AvatarsModal from '../components/AvatarsModal'
import { Carousel, Button, message, Popconfirm } from "antd";
import { ShareAltOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Score, fetchname, fetchstufflist, fetchaccountdata, setdefaultavatar } from '../request/api'
import store from '../store'


const Edit = () => {

  const [utoken, setUtoken] = useState("a32bb94563137eea98f1fd09e1ddcb6b6686ae39b7e10cc541231654b1bf4")
  const [gid, setGid] = useState("2048G")
  const [changeAvatarid, setChangeAvatarid] = useState(-1)
  useEffect(() => {

    // let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
    // // total_credits

    // setcredits(gamer_reg.total_credits)

    // 监听state的变化
    let unsubscribe = store.subscribe(() => {

      setChangeAvatarid(store.getState().changeAvatarid)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  const confirm = (e) => {
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))

    let UUID = gamer_reg.uuid
    console.log(e);
    setdefaultavatar({
      uuid: UUID,
      stuff_id: e.property.id,
    }).then((res) => {
      console.log(res);
      // res.data.current_avatar_url
      const action3 = {
        type: "changeAvatar",       // type属性是必须要写的，用于校验
        value: res.data.current_avatar_url,          // value代表要修改为什么值
      }
      // 将action用dispatch方法传递给store
      store.dispatch(action3);
      const action4 = {
        type: "changeAvatarid",       // type属性是必须要写的，用于校验
        value: res.data.current_avatar_id,          // value代表要修改为什么值
      }

      store.dispatch(action4);
      gamer_reg.avatar_url = res.data.current_avatar_url + ""
      gamer_reg.avatar_id = res.data.current_avatar_id + ""
      localStorage.setItem("gamer_reg", JSON.stringify(gamer_reg));
    })
    // message.success('Click on Yes');
  };
  const cancel = (e) => {
    console.log(e);
    // message.error('Click on No');
  };
  const contentStyle = {
    height: "400px",
    lineHeight: "400px",
    textAlign: "center",
    background: "#364d79",
  };
  const carouselEL = useRef(null);
  const [show, setShow] = useState(null)
  const [resscore, setResscore] = useState({ game_name: "2048 Game" })
  let [time, setTime] = useState([1])
  let [scores, setScores] = useState([1])
  let [comscore, setComscore] = useState([])
  let [comscoreb, setComscoreb] = useState([])
  let [topscore, setTopscore] = useState([])
  let [playtotal, setPlaytotal] = useState(0)
  let [dialog, setDialog] = useState(false)
  let [avatarsdialog, setAvatarsdialog] = useState(false)
  let [avatarsdialogdata, setAvatarsdialogdata] = useState({})
  let [topplayer, setTopplayer] = useState([])
  let [alist, setAlist] = useState([])
  let [mylist, setMylist] = useState([])
  let [xx, setXx] = useState([])
  let [yy, setYy] = useState([])
  //  游客用户名
  let [tourist, setTourist] = useState("")

  useEffect(() => {

    if (localStorage.hasOwnProperty("utoken") == false) {
      setShow(false)
      return
    }
    let UT = JSON.parse(localStorage.getItem("utoken"))

    // if(UT=="{$utoken}"){
    //   UT="c351812e7f74be6342d297ad02a7840d96be1e28d9840db343169e10814f7"
    //   console.log(UT);
    //   localStorage.setItem("utoken",UT);
    // }else if(UT=="none"){
    //   setShow(false)
    //   return
    // }

    // 进入子组件 首先判断缓存中有没有游戏数据
    if (localStorage.hasOwnProperty("gamer_reg") == false) {
      // 缓存中没有游戏数据 调用接口获取
      console.log("首次进入 缓存中没有游戏数据 调用接口获取");
      fetchname({
        utoken: UT,

      }).then(
        (res) => {
          console.log(res);
          localStorage.setItem("gamer_reg", JSON.stringify(res.data));

          if (res.data.init_state == 1) {
            let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
            setTourist(gamer_reg.player_name)
            setDialog(true)
          }
          scoresShow()

        })
    } else {
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
      if (gamer_reg.init_state == 1) {
        setTourist(gamer_reg.player_name)
        setDialog(true)
      }
      scoresShow()
      console.log("缓存中有游戏数据");
    }








  }, [])
  function scoresShow() {
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
    let UT = JSON.parse(localStorage.getItem("utoken"))
    let UUID = gamer_reg.uuid

    fetchstufflist({
      uuid: UUID,
      type: "avatar"
    }).then((res) => {
      console.log(res);
      setAlist(res.data)
    })
    fetchaccountdata({
      uuid: UUID,
      type: "avatar"
    }).then((res) => {
      console.log(res);
      if (res.code == "2") {
        let obj = []
        setMylist(obj)
      } else {
        setMylist(res.data)

      }

    })
    if (gamer_reg.gamer_reg[gid] == "reg") {

      setShow(true)
      Score({
        // utoken: UT,
        uuid: UUID,
        gid: gid
      }).then(
        (res) => {
          console.log("该传给子组件", res);

          // bus.emit('sendVal',res.data.player_name)
          const action = {
            type: "changeInputValue",       // type属性是必须要写的，用于校验
            value: res.data.player_name,          // value代表要修改为什么值
          }
          const action2 = {
            type: "changeCredits",       // type属性是必须要写的，用于校验
            value: gamer_reg.total_credits,          // value代表要修改为什么值
          }
          const action3 = {
            type: "changeAvatar",       // type属性是必须要写的，用于校验
            value: gamer_reg.avatar_url,          // value代表要修改为什么值
          }
          const action4 = {
            type: "changeAvatarid",       // type属性是必须要写的，用于校验
            value: gamer_reg.avatar_id,          // value代表要修改为什么值
          }
          const action6 = {
            type: "changeGifid",       // type属性是必须要写的，用于校验
            value: gamer_reg.cheerleader_id,          // value代表要修改为什么值
          }
          if (gamer_reg.cheerleader_gif_url !== null) {
            const action5 = {
              type: "changeGif",       // type属性是必须要写的，用于校验
              value: JSON.parse(gamer_reg.cheerleader_gif_url)[0],          // value代表要修改为什么值
            }
            store.dispatch(action5);
          }
          store.dispatch(action4);

          store.dispatch(action3);
          store.dispatch(action2);
          store.dispatch(action6);
          store.dispatch(action);

          setResscore(res.data)
          // console.log(res.data.community_data.com_score_phases,JSON.parse(res.data.community_data.com_score_phases));
          setComscore(JSON.parse(res.data.community_data.com_score_phases))

          setComscore(comscore => {
            // console.log("函数式更新",comscore);
            return comscore
          })
          setPlaytotal(res.data.community_data.com_total_times)
          setTopscore(JSON.parse(res.data.community_data.top_scores))


          setTopplayer(JSON.parse(res.data.community_data.top_players))
          // console.log("高端玩家信息",JSON.parse(res.data.community_data.top_players));
          let uscores = []
          let utime = []
          for (let i = 0; i < res.data.scores.length; i++) {

            uscores.push(res.data.scores[i].score)
            utime.push(res.data.scores[i].addtime)
          }
          scores = uscores
          time = utime
          setScores([...scores])
          setTime([...time])

          // console.log(comscore,"这是comscore11",res.data.community_data.com_total_times);

          let cssj = JSON.parse(res.data.community_data.com_score_phases)
          let index_cx = []
          let index_cy = []
          for (let i = 0; i < cssj.length; i++) {
            if (cssj[i].score == res.data.last_score) {
              let obj = {
                value: cssj[i].num,
                itemStyle: {
                  color: '#a90000'
                }
              }
              index_cx.push(cssj[i].score)
              index_cy.push(obj)
            } else {
              index_cx.push(cssj[i].score)
              index_cy.push(cssj[i].num)
            }
          }
          // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",index_cx,index_cy,res.data.last_score);
          setXx(index_cx)
          setYy(index_cy)
          const xdata = []
          for (let i = 0; i < JSON.parse(res.data.community_data.com_score_phases).length; i++) {

            let obj = {
              value: JSON.parse(res.data.community_data.com_score_phases)[i].num,
              name: JSON.parse(res.data.community_data.com_score_phases)[i].score
            }
            xdata.push(obj)
          }
          comscoreb = xdata
          setComscoreb([...comscoreb])
          // console.log(xdata,"这是Xdata",comscoreb);
        })
    } else {
      setShow(false)
    }
  }
  function closemodel() {
    console.log("父组件的关闭弹出框");
    setDialog(false)


  }
  function avatarsmodel() {
    console.log("父组件的关闭弹出框");
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))

    let UUID = gamer_reg.uuid

    fetchstufflist({
      uuid: UUID,
      type: "avatar"
    }).then((res) => {
      console.log(res);
      setAlist(res.data)
    })
    fetchaccountdata({
      uuid: UUID,
      type: "avatar"
    }).then((res) => {
      console.log(res);
      if (res.code == "2") {
        let obj = []
        setMylist(obj)
      } else {
        let obj = res.data
        setMylist([...obj])
        console.log(mylist);
      }

    })
    setAvatarsdialog(false)


  }
  function buyAvatars(e) {
    console.log(e);
    setAvatarsdialogdata(e)
    setAvatarsdialog(true)
  }

  return (
    <div className='Edit'>
            <div  className='bt' style={{display:show==true?'block':'none'}}>
       My Game Data
      </div>
      <div  className='bt' style={{display:show==false?'block':'none'}}>
      Game Overview
      </div>
      <div className='edit-mark' style={{ display: show == true ? 'block' : 'none' }}>
        {/* <div className='edit-header'>
            <div className='left'> <ShareAltOutlined />
        
        <span className='smallfont'>SHARE</span></div>
            <div className='right'>
         
        
        <span className='smallfont-right'>Enter Reading Mode</span>
        <ShareAltOutlined />
                   
  
            
            </div>



       
        </div> */}
        <div className='edit-center'>
          <div><div className='edit-img'>
            {/* <h4>Game pictures:</h4> */}
            <img className='edit-img-banner' src={require('../assets/img/thumbnails/2048-game.png')} alt="" />
            <div className='edit-mark2'>
              <p className='li1'>{resscore.game_name}</p>
              <ul className='Personal-Data'>
                {/* <li className='li1'>{resscore.game_name}</li> */}
                <h4>Personal Information:</h4>
                <li>Current score: <span style={{ color: "blue", marginLeft: "10px" }}>{resscore.last_score}</span> </li>

                <li>Converted Credits: <span style={{ color: "blue", marginLeft: "10px", border: "none" }}>{resscore.last_credits}<img src={require('../assets/img/credit.png')} alt="" /></span> </li>
                <li>Max score: <span style={{ color: "green", marginLeft: "10px" }}>{resscore.max_score}</span> </li>
                <li>Play times:<span style={{ color: "#BD8D27", marginLeft: "10px" }}>{resscore.total_times}</span></li>


              </ul>
              <ul className='Personal-Data Personal-Datatwo' >

                <h4>&nbsp;</h4>
                <h5>"{resscore.top_ranking_slogn}"</h5>
                <li>Top </li>
                <li className='top_ranking'>{resscore.top_ranking}</li>
                {/* <li>Current Credits: <span style={{color:"blue",marginLeft:"10px",border:"none"}}>{resscore.last_credits}<img  src={require('../assets/img/credit.png')} alt="" /></span> </li>
                <li>All Credits: <span style={{color:"green",marginLeft:"10px",borderRight:"none"}}>{resscore.credits}<img  src={require('../assets/img/credit.png')} alt="" /></span> </li>     */}




              </ul>

            </div>

            <div className='edit-mark3'>







            </div>
          </div>


          </div>
          <div>
            <Bar
              title='Score History:'
              xData={time}
              yData={scores}
              style={{ width: '700px', height: '350px', float: "left" }} />
            {/* <Bar_c
      title='Ranking: '
      xData={xx}
      yData={yy}
      style={{ width: '700px', height: '350px',float:"left" }} /> */}

            <Bar_b
              title='Community Performance:'
              xData={comscoreb}

              style={{ width: '700px', height: '500px', float: "left" }} />

          </div>



          {/* <div className='table_one'>


        <h4 className='table-h4'>Community Performance:  <span className='playtotal'>{playtotal}  Plays</span></h4>
        <table className="edit-table">
    
                <thead>

                    <tr>
                        <th className='th1'>Scores</th>
                        <th className='th2'>Statistics</th>
                        <th className='th3'>Your Rank</th>
                    </tr>
                </thead>
                <tbody>
                {comscore.map((item, idx) => (
      
           <tr key={idx} className="edit-tr">
           <td  className='td1'>{item.score}</td>
           <td className='td2'>
            <div className='td2-div' style={{ width: item.num/playtotal*100 + "%" }}></div>
            <span>count:{item.num} | {Math.trunc(item.num/playtotal*100) }%</span>
            
           </td>
           <td className='td3'>
            <div style={{display:resscore.last_score==item.score?'block':'none'}}> <img src="https://www.dragmaze.com/custom/images/good.png" alt="" /></div>
           </td>
        
       </tr>
    ))
}
             
              
                </tbody>
            </table>
     </div> */}
          <div className='table_one'>
            <h4>Top Players:</h4>
            <table className="edit-table2">
              <thead>

                <tr>
                  <th className='th1'>Rank</th>
                  <th className='th2'>Avatars  </th>
                  <th className='th2'>Player  </th>
                  <th className='th3'> Max scores</th>
                </tr>
              </thead>
              <tbody  >
                {topplayer.map((item, idx) => (
                  <tr key={idx} className={['edit-tr', idx == 0 ? 'className0' : null, idx == 1 ? 'className1' : null, idx == 2 ? 'className2' : null].join(' ')} style={{ display: idx < 10 ? 'table-row' : 'none' }}>
                    <td className='td1'>{idx + 1} </td>
                    <td className='td2'>
                      <img className='td2-img' src={item.player_avatar} alt="" />
                    </td>
                    <td className='td3'>{item.player_name} </td>

                    <td className='td4'>{item.max_score}</td>
                  </tr>

                ))
                }
              </tbody>

            </table>
          </div>
          {/* <div className='table_two'>
     <h4>Best Scores:</h4>
            <table className="edit-table2">
            <thead>

<tr>
    <th className='th1'>Rank</th>
    <th className='th2'>  Score</th>
    <th className='th3'>Player </th>
</tr>
</thead>
<tbody  >
                {topscore.map((item, idx) => (
    <tr key={idx} className={['edit-tr',idx==0?'className0':null,idx==1?'className1':null,idx==2?'className2':null].join(' ')}  style={{display:idx<10?'table-row':'none'}}>
      <td  className='td1'>{idx+1} </td>
<td  className='td2'> {item.score}</td>
<td className='td3'>{item.player_name}</td>
    </tr>

))
}
</tbody>   

            </table>
     </div> */}


        </div>

        <div className='edit-center'>
          <div className='cheer'>
            <h4>New Avatars</h4>
            <ul className='cheer_list'>
              {alist.map((item, idx) => (

                <li key={idx} onClick={() => { buyAvatars(item) }} >
                  <img className='cheer_list_banner' src={item.url} alt="" />
                  <p>
                    <img src={require('../assets/img/cheerleaders/lock.png')} alt="" />
                    <span>
                      <span>{item.credit_price}</span>

                      <img className='credit_img' src={require('../assets/img/credit.png')} alt="" />
                    </span>

                  </p>
                </li>



              ))
              }


            </ul>

          </div>
          <div className='cheer' style={{ display: mylist.length == 0 ? 'none' : 'block' }}>

            <h4>My Avaters</h4>
            <ul className='cheer_list'>

              {mylist.map((item, idx) => (
                <div key={idx}>
                  <li style={{ display: changeAvatarid == item.property.id ? 'none' : 'block' }}>
                    <Popconfirm
                      title="Make this avatar as current cover"

                      onConfirm={() => confirm(item)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <img className='cheer_list_banner' src={item.property.url} alt="" />
                      <p>
                        {/* <img  src={require('../assets/img/cheerleaders/unlock.png')} alt="" /> */}
                        <span>

                        </span>

                      </p>


                    </Popconfirm>

                  </li>
                  <li className='cheer_list_current' style={{ display: changeAvatarid == item.property.id ? 'block' : 'none' }} >

                    <img className='cheer_list_banner' src={item.property.url} alt="" />
                    <p className='cheer_list_current_p'>


                      CURRENT


                    </p>



                  </li>
                </div>
                // changeAvatarid

              ))
              }


            </ul>

          </div>
          <div className='cheer' style={{ display: mylist.length == 0 ? 'block' : 'none' }}>
            <h4>My Avaters</h4>
            <p>You haven't got your avatar yet. Earn credits and unlock one.</p>

          </div>
        </div>
      </div>

      {/* 这是没有玩过的游戏 */}
      <div className='edit-details' style={{ display: show == false ? 'block' : 'none' }}>
        <div className='details-center'>
          <h4>1. Introduction</h4>
          <p>This chrome extension allows you to play 2048 game anytime and anywhere.</p>
          <hr />
          <h4>2. Installation</h4>
          <p>You can download and install it from Chrome store:</p>
          <a href="https://chrome.google.com/webstore/detail/2048-game/gekanafgchokbnoomokmmifkbnckkajk">
            https://chrome.google.com/webstore/detail/2048-game/gekanafgchokbnoomokmmifkbnckkajk

          </a>
          <hr />
          <h4>3. Key Features</h4>
          <ul>
            <li> The extension is an original 2048 game in a popup and accessible on a browser panel: </li>
            <li>The game can be played intermittently and the extension can remember where you left off last time.</li>
            <li>One feature you may like is that larger tiles are used in game interface for easy view.</li>

          </ul>
          <hr />
          <h4>4. How to Play</h4>
          <h5>1) The rule of 2048 game:</h5>
          <ul>

            <li>When two tiles with the same number touch, they merge into one.</li>
            <li>When two tiles are merged, you earn points.</li>
            <li>The goal is to create a tile with the number 2048 by multiple merges .</li>
            <li>You can continue to play and earn more points after reaching the goal.</li>
          </ul>
          <h5>2) The control of 2048 game:</h5>
          <ul>

            <li>Click 2048 icon in the top right corner of browser to start a game.</li>
            <li>Use the ‘left/right/up/down’ arrow key in keyboard to move tiles.</li>
            <li>Click ‘new’ button to start a new game anytime.</li>

          </ul>
          <hr />
          <h4>5. Screenshots</h4>
          <div className='zmd'>
            <Button
              className="leftButton"
              style={{ left: 55 }}
              onClick={() => {
                carouselEL.current.prev();
              }}
              icon={<LeftOutlined />}
            ></Button>
            <Button
              className="rightButton"
              style={{ right: 55 }}
              onClick={() => {
                carouselEL.current.next();
              }}
              icon={<RightOutlined />}
            ></Button>


            <Carousel
              autoplay

              ref={carouselEL}
            >
              <div>
                <h3 style={contentStyle}>
                  <div className="img">
                    <img src={require('../assets/img/screenshots/2048-game/2048-screenshot-1.png')} alt="" />
                  </div>
                </h3>
              </div>
              <div>
                <h3 style={contentStyle}>   <div className="img">
                  <img src={require('../assets/img/screenshots/2048-game/2048-screenshot-2.png')} alt="" />
                </div></h3>
              </div>
              <div>
                <h3 style={contentStyle}>   <div className="img">
                  <img src={require('../assets/img/screenshots/2048-game/2048-screenshot-3.png')} alt="" />
                </div></h3>
              </div>

            </Carousel>
          </div>

        </div>

      </div>
      <Modal
        xdata={dialog}
        setPare={closemodel}
        tourist={tourist}


      ></Modal>
      <AvatarsModal
        xdata={avatarsdialog}
        ydata={avatarsdialogdata}
        setPare={avatarsmodel}
        type="avatar"
      ></AvatarsModal>
    </div>
  );
}

export default Edit;
