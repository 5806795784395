import request from './request'

// 注册
export const RegisterApi = (params) => request.post('/register', params)
    // 登陆
export const LoginApi = (params) => request.post('/login', params)

// 获取个人信息
export const fetchname = (params) => request.post('/gamers/fetchbaseinfo', params)


// 获取列表
export const Score = (params) => request.post('/gamers/score', params)
    // http://chromegamers.win-eco.cn/api/gamers/score

// 登录
// export const openaccount = (params) => request.post('/gamers/openaccount', params)
export const openaccount = (params) => request.post('/gamers/renewaccount', params)
    //临时登录
export const touristopenaccount = (params) => request.post('/gamers/openaccount', params)
    // 退出登录
export const logout = (params) => request.post('/gamers/logout', params)

// 获取头像列表
export const fetchstufflist = (params) => request.post('/stuff/fetchstufflist', params)

// 获取已解锁头像
export const fetchaccountdata = (params) => request.post('/gamers/fetchaccountdata', params)

// 解锁头像
export const purchase = (params) => request.post('/stuff/purchase', params)

// 使用头像
export const setdefaultavatar = (params) => request.post('/stuff/setdefaultavatar', params)

// 使用动图
export const setdefaultcheerleader = (params) => request.post('/stuff/setdefaultcheerleader ', params)

// 获取名称是否重复
export const checkplayername = (params) => request.post('/gamers/checkplayername ', params)


// 获取message
export const msglist = (params) => request.post('/message/msglist', params)